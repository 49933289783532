<template>
	<div class="p-grid p-nogutter contact-profil">
		<div class="p-col-12 p-nogutter">
			<InfiniteProgress v-show="loading"></InfiniteProgress>
			<div v-if="profile !== undefined" class="p-grid p-nogutter">
				<Dialog header="Confirmation" v-model:visible="deleteDialog" :style="{width: '350px'}" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="display:block; font-size: 2rem" />
						<span>Êtes-vous sur de vouloir supprimer ce contact ?</span>
					</div>
					<template #footer>
						<Button label="Non" icon="pi pi-times" @click="deleteDialog = false" class="p-button-text"/>
						<Button label="Oui, supprimer" icon="pi pi-check" @click="deleteContact($event); deleteDialog = false"
								class="p-button-text" autofocus />
					</template>
				</Dialog>

				<div style="text-align: right" class="p-col-12 p-mb-5">
					<Menu ref="menu" :model="overlayMenuItems" :popup="true" />
					<Button type="button" class="b-button p-button-outlined" label="Actions" icon="pi pi-angle-down" @click="toggleMenu" style="width: auto"/>
				</div>

				<div class="p-col-12 p-md-6">

					<div class="p-fluid p-m-2" style="text-align: center">
						<div class="p-fluid">
							<img v-if="profile.gender === 3" src="assets/layout/images/avatar_4.png" width="50" alt="avatar4"/>
							<img v-if="profile.gender === 2" src="assets/layout/images/avatar_3.png" width="50" alt="avatar3"/>
							<img v-if="profile.gender === 1" src="assets/layout/images/avatar_1.png" width="50" alt="avatar1"/>
							<img v-if="profile.gender === 0" src="assets/layout/images/avatar_2.png" width="50" alt="avatar2"/>
						</div>
						<h4> <small v-if="profile.gender === 1">Mlle </small><small v-if="profile.gender === 0">Mr </small>
							{{ profile.lastname }} {{ profile.firstname }}</h4>
						<p>{{ profile.company }}</p>
						<div class="w-100 p-mb-3">
							<div @click="editStatus = true" v-if="!editStatus" class="w-100">
								<div v-if="profile.status === 1" class="p-message p-component  p-message-info">
									<div class="p-message-wrapper">
										<b>Nouveau</b>
									</div>
								</div>
								<div v-else-if="profile.status === 2" class="p-message p-component  p-message-error">
									<div class="p-message-wrapper">
										<b>Non-Qualifié</b>
									</div>
								</div>
								<div v-else-if="profile.status === 3" class="p-message p-component  p-message-info">
									<div class="p-message-wrapper">
										<b>Proposition</b>
									</div>
								</div>
								<div v-else-if="profile.status === 4" class="p-message p-component  p-message-success">
									<div class="p-message-wrapper">
										<b>Qualifié</b>
									</div>
								</div>
								<div v-else-if="profile.status === 5" class="p-message p-component  p-message-warn">
									<div class="p-message-wrapper">
										<b>Renouvellement</b>
									</div>
								</div>
								<div v-else-if="profile.status === 6" class="p-message p-component  p-message-warn">
									<div class="p-message-wrapper">
										<b>Négotiation</b>
									</div>
								</div>
								<small class="text-muted">Cliquez sur le statut pour le modifier</small>
							</div>
							<div v-else class="w-100">
								<ContactStatusSingleSelector @change="onChangeStatus" :value="profile.status"></ContactStatusSingleSelector>
							</div>
						</div>

						<div style="text-align: center" class="p-grid p-nogutter p-mb-3">
							<div class="p-col-12">
								<HotBar @vchange="onChangeHot" :editable="true" :value="profile.hotRate"></HotBar>
								<small class="text-muted">Cliquez sur la barre pour modifier la valeur</small>
							</div>
						</div>


						<div style="text-align: left" class="p-grid p-nogutter">
							<div class="p-col-12">
								<ul class="activity-list">
									<hr class="p-mr-3">

									<li  v-if="profile.street !== '' && profile.city !== ''" class="p-mb-4">
										<div class="p-d-flex p-jc-between">
											<h6 class="text-muted activity p-mb-1">Adresse</h6>
										</div>
										<p><a @click.prevent="searchInGoogleMap" href="#">
											{{ profile.street }}<br>
											{{ profile.postalCode }} {{ profile.city }}<br>
											{{ profile.country }}</a>
										</p>
									</li>
									<li class="p-mb-4">
										<div class="p-d-flex p-jc-between">
											<h6 class="text-muted activity p-mb-1">Téléphone</h6>
										</div>
										<p>
											<a :href="'tel:' + profile.phone">{{ profile.phone }}</a>
										</p>
									</li>
									<li v-if="profile.phone2 !== ''" class="p-mb-4">
										<div class="p-d-flex p-jc-between">
											<h6 class="text-muted activity p-mb-1">Téléphone 2</h6>
										</div>
										<p>
											<a :href="'tel:' + profile.phone2">{{ profile.phone2 }}</a>
										</p>
									</li>
									<li class="p-mb-4">
										<div class="p-d-flex p-jc-between p-ai-center">
											<h6 class="text-muted activity p-mb-1">E-mail</h6>
										</div>
										<p v-if="profile.email.length <= 5">{{ profile.email }}</p>
										<p v-else>
											<a :href="'mailto:' + profile.email">{{ profile.email }}</a>
										</p>
									</li>
									<li class="p-mb-4">
										<div class="p-d-flex p-jc-between p-ai-center">
											<h6 class="text-muted activity p-mb-1">Website</h6>
										</div>
										<p v-if="profile.website.length <= 5">{{ profile.website }}</p>
										<p v-else>
											<a target="_blank" rel="noopener norefferer" :href="toUrl(profile.website)">{{ profile.website }}</a>
										</p>
									</li>
									<li v-if="profile.source === null" class="p-mb-4">
										<div class="p-d-flex p-jc-between p-ai-center">
											<h6 class="text-muted activity p-mb-1">Source</h6>
										</div>
										<p>{{ profile.sourceTxt }}</p>
									</li>

									<li v-if="profile.source !== null" class="p-mb-4">
										<div class="p-d-flex p-jc-between p-ai-center">
											<h6 class="text-muted activity p-mb-1">Source</h6>
										</div>

										<CampagneDisplay :apiId="profile.source"></CampagneDisplay>
									</li>



									<!-- todo display source if source is a campaign -->

									<hr class="p-mr-3">

									<li class="p-mb-4">
										<div class="p-d-flex p-jc-between p-ai-center">
											<h6 class="text-muted activity p-mb-1">Premier Contact</h6>
										</div>
										<p>{{ tmsToDate(profile.dateFirstContact) }}</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="p-col-12 p-md-6 activity-content">
					<h5>Activité</h5>


					<div class="p-fluid">
						<div v-if="profile.messages.length > 0" class="p-fluid">
							<div v-for="message in profile.messages" :key="message" class="p-fluid">
								<MessageDisplay :guid="message"></MessageDisplay>
							</div>
						</div>
						<div v-else class="p-fluid p-d-flex p-ai-center p-justify-center p-m-5">
							<ErrorMessageBc type="message" message="Aucun message" detail="Ce prospect ne vous a jamais envoyé de message ☹"></ErrorMessageBc>
						</div>
					</div>

					<h5>Rendez-vous</h5>
					<div class="p-fluid">
						<div v-if="calendarEvents !== undefined && calendarEvents.length > 0" class="p-grid p-nogutter">
							<div v-for="evt in calendarEvents" :key="evt.googleId" class="p-col-12 p-nogutter">
								<div v-bind:class="{'event-muted': isBefore(evt.start)}" class="card event-card">
									<div class="status-tag">
										<span class="p-tag p-tag-rounded"
												v-bind:class="{'p-tag-success': evt.status === 2,
												 'p-tag-info': evt.status === 0 ||  evt.status === 1,
												  'p-tag-danger': evt.status === 3 ||  evt.status === 4 ||  evt.status === 5,}">
											{{ statusToStr(evt.status,true) }}
										</span>
									</div>
									<div class="p-d-flex p-jc-between p-ai-center header-small-text p-mb-2">
										<div class="left">
											<span class="p-menuitem-icon pi pi-calendar p-mr-2"></span>
											<b>Rendez-vous</b>
										</div>
										<div class="right">
											<span class="text-muted">{{ isoRangeToHumanRange(evt.start,evt.end) }}</span>
										</div>
									</div>
									<div class="p-fluid">
										<span class="event-title">{{ evt.title }}</span>
										<p class="message_content text-muted p-m-2">{{ evt.text }}</p>
									</div>
									<div v-if="!isBefore(evt.start) && canEditStatus(evt.status)" class="p-fluid">
										<div class="p-field">
											<Button label="Éditer le statut"
													@click="evt.dialog = true"
													icon="pi pi-pencil" class="p-button b-button p-mt-2 p-mb-2"></Button>
										</div>
									</div>

								</div>

								<Dialog v-model:visible="evt.dialog" :style="{width: '450px'}" header="Détail événement" :modal="true" :closable="true">
									<div v-if="evt !== undefined && evt['@id'] !== undefined" class="p-fluid">
										<EditEvent :loadProfile="false" :guid="evt['@id']"></EditEvent>
									</div>
								</Dialog>

							</div>
						</div>
						<div v-else class="p-fluid  p-d-flex p-ai-center p-justify-center p-m-5">
							<ErrorMessageBc type="calendar" message="Aucun rendez-vous" detail="Ce prospect ne vous a pas proposé de rendez-vous ☹"></ErrorMessageBc>
						</div>

					</div>

				</div>

			</div>
		</div>
	</div>
</template>

<script>
import CrmService from "../../../service/CrmService";
import MessageDisplay from "./MessageDisplay.vue";
import ContactStatusSingleSelector from "./ContactStatusSingleSelector";
import HotBar from "./HotBar.vue";
import InfiniteProgress from '../../../components/InfiniteProgress.vue';
import CalendarService from "../../../service/CalendarService";
import EditEvent from '../calendar/EditEvent.vue';
	import calendarMixin from "../../../mixins/calendarMixin";
	import CampagneDisplay from './CampagneDisplay.vue'

export default {
	data () {
		return {
			profile: undefined,
			editStatus: false,
			loading: false,
			timeoutPtr: undefined,
			deleteDialog: false,
			calendarEvents: undefined,
			overlayMenuItems: [
				{
					label: 'Recherche sur google',
					icon: 'pi pi-external-link',
					command: () => {
						this.searchInGoogle();
					}
				},
				{
					label: 'Exporter en .CSV',
					icon: 'pi pi-download',
					command: () => {
						this.downloadData();
					}
				},
				{
					separator: true
				},
				{
					label: 'Supprimer',
					icon: 'pi pi-trash',
					command: () => {
						this.deleteDialog = true;
					}
				},
			],
		}
	},
	created(){
		this.crmService = new CrmService();
		this.calendarService = new CalendarService();
	},
	props: {
		guid: {}
	},
	mounted(){
		this.loadData();
	},
	methods: {
		toUrl(url){
			if(url.includes('https') || url.includes('https') || url.includes('//')){
				return url;
			}
			return '//' + url;
		},
		downloadData(){
			this.loading = true;
			this.crmService.downloadOneContact(
					this.profile.guid
			)
					.finally(() => {
						this.loading = false;
					})
		},
		searchInGoogleMap(){
			this.openLinkNewWindow(
					'https://www.google.fr/maps/search/' + this.profile.street + ' ' + this.profile.postalCode + ' ' + this.profile.city + ' ' + this.profile.country
			);
		},
		searchInGoogle(){
			this.openLinkNewWindow(
					'https://google.com/search?q=' + this.profile.lastname + ' ' + this.profile.firstname + ' ' + this.profile.company
			);
		},
		openLinkNewWindow(lnk){
			let link = document.createElement("a");
			link.setAttribute('target', '_blank');
			link.href = lnk;
			document.body.appendChild(link);
			link.click();
			link.remove();
		},
		toggleMenu(event) {
			this.$refs.menu.toggle(event);
		},
		registerReload(){
			const vm = this;
			if(this.timeoutPtr !== undefined){
				this.cancelReload();
			}
			this.timeoutPtr = setTimeout(() => {
				vm.loading = true;
				vm.crmService.updateContact('/api/contacts/' + vm.guid, vm.profile)
						.then(data => {
							vm.profile = data.data;
							vm.$toast.add({severity:'success', summary: 'Succès', detail:'Informations mises à jour !', life: 3000});
							vm.editStatus = false;
						})
						.catch(err => {
							vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							vm.loading = false;
						})
			}, 400)
		},
		cancelReload(){
			try {
				clearTimeout(this.timeoutPtr);
			} catch (e) {
				console.warn(e);
			}
		},
		deleteContact(){
			const vm = this;
			if(this.timeoutPtr !== undefined){
				this.cancelReload();
			}
			this.timeoutPtr = setTimeout(() => {
				vm.loading = true;
				vm.crmService.deleteContact('/api/contacts/' + vm.guid)
						.then(data => {
							vm.profile = data.data;
							vm.$toast.add({severity:'success', summary: 'Succès', detail:'Contact supprimé !', life: 3000});
							vm.$router.push({ name: 'crm-contacts' });//redirect to contact list
						})
						.catch(err => {
							vm.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							vm.loading = false;
						})
			}, 400)
		},
		loadData(){
			const vm = this;
			vm.loading = true;
			this.crmService.getContact('/api/contacts/' + this.guid)
					.then(data => {
						vm.profile = data.data;
					})
					.finally(() => {
						vm.loading = false;
					})
			;

			this.calendarService.listCalendarEvent(this.guid, undefined)
					.then(res => {
						vm.calendarEvents = res.data.data;
					})
					.finally(() => {
						//vm.loading = false;
					})
			;
		},
		onChangeHot(event){
			this.profile.hotRate = Number(event);
			this.registerReload();

		},
		onChangeStatus(event){
			this.profile.status = event;
			this.registerReload();
		},

	},
	components: {
		MessageDisplay,
		ContactStatusSingleSelector,
		HotBar,
		InfiniteProgress,
		EditEvent,
		CampagneDisplay,
	},
	mixins: [calendarMixin]
}
</script>

<style scoped>

</style>
