<template>
	<div class="selector input-field">
		<div v-if="dropdownValues !== undefined" class="w-100 selector-inner">
			<Dropdown  v-model="innerValue" id="link_type" @change="updateValue"
                      :options="dropdownValues" optionLabel="name" placeholder="Selectionnez un statut(s)" />
		</div>
	</div>
</template>

<script>
	/*
         public const STATUS_NEW = 1;
         public const STATUS_UNQUALIFIED = 2;
         public const STATUS_PROPOSAL = 3;
         public const STATUS_QUALIFIED = 4;
         public const STATUS_RENEWAL = 5;
         public const STATUS_NEGOTIATION = 6;
         */

export default {
	data() {
		return {
			dropdownValues: [
				{
					name: "Nouveau",
					code: 1,
				},
				{
					name: "Non-Qualifié",
					code: 2,
				},

				{
					name: "Proposition",
					code: 3,
				},
				{
					name: "Qualifié",
					code: 4,
				},

				{
					name: "Renouvellement",
					code: 5,
				},
				{
					name: "Négotiation",
					code: 6,
				},

			],
			innerValue: undefined,
		}
	},
	props: {
		value: {}
	},
	created() {

	},
	mounted() {
		this.dropdownValues.forEach(el => {
			if(el.code == this.value){
				this.innerValue = el;
			}
		})
	},
	methods: {
		updateValue(event) {
			this.$emit('input', event.value.code);
			this.$emit('change', event.value.code);
		}
	}
}
</script>

<style scoped>

</style>
