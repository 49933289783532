<template>
	<div class="hot-bar">
		<div v-if="innerValue !== undefined" class="slider-container">
			<input :disabled="!editable" @input="onChange" type="range" min="0" max="100" v-model="innerValue" class="slider">
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			innerValue: undefined,
		}
	},
	props: {
		value: {},
		editable: {
			type: Boolean,
			default: false,
		}
	},
	created(){
		this.innerValue = this.value;
	},
	mounted(){
	},
	methods: {
		onChange(event){
			this.innerValue = event.target.value;
			this.updateValue();
		},
		updateValue() {
			this.$emit('vchange', this.innerValue);
		}
	}
}
</script>

<style lang="scss">

	.hot-bar {
		width: 100%;
		position: relative;
	}

	.slider-container {
		position: relative;
		width: 100%;
		padding: 0;
		input {
			position: relative;
			width: 101%;
			height: 10px;
			left: -3px;
			-webkit-appearance: none;  /* Override default CSS styles */
			appearance: none;
			background: rgb(0,151,255);
			background: linear-gradient(90deg, rgba(0,151,255,1) 0%, rgba(127,209,0,1) 50%, rgba(252,69,69,1) 100%);
			outline:0!important;
		}

		input::-webkit-slider-thumb {
			-webkit-appearance: none; /* Override default look */
			appearance: none;
			width: 5px; /* Set a specific slider handle width */
			height: 15px; /* Slider handle height */
			background: #222; /* Green background */
			cursor: pointer; /* Cursor on hover */
		}

		input::-moz-range-thumb {
			width: 5px; /* Set a specific slider handle width */
			height: 15px; /* Slider handle height */
			background: #222; /* Green background */
			cursor: pointer; /* Cursor on hover */
		}

		input:disabled::-webkit-slider-thumb {
			opacity: .6;
			cursor: default;
		 }

		input:disabled::-moz-range-thumb {
			opacity: .3;
			cursor: default;
		}
	}
</style>
