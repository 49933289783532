<template>
	<div class="p-grid p-nogutter">
		<div class="p-col-12 p-nogutter">
			<div v-if="message !== undefined" class="card">
				<div class="p-d-flex p-jc-between p-ai-center header-small-text p-mb-2">
					<div class="left">
						<span class="p-menuitem-icon pi pi-calendar p-mr-2"></span>
						<b>Message</b>
					</div>
					<div class="right">
						<span class="text-muted">{{ tmsToDate(message.dateCreation) }}</span>
					</div>
				</div>

				<p class="message_content">{{ message.content }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import CrmService from "../../../service/CrmService";
import calendarMixin from "../../../mixins/calendarMixin";

export default {
	data () {
		return {
			message: undefined,
		}
	},
	created(){
		this.crmService = new CrmService()
	},
	props: {
		guid: {}
	},
	mounted(){
		this.loadData();
	},
	methods: {
		loadData(){
			const vm = this;
			this.crmService.getContact(this.guid)
					.then(data => {
						vm.message = data.data;
					})
			;
		},
	},
	mixins:[calendarMixin]
}
</script>

<style lang="scss" scoped>
	.header-small-text {
		font-size: .8rem;
	}

	.message_content {
		padding:1rem;
	}
	.activity-list {
		li {
			list-style: none;
		}
	}
</style>
