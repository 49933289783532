<template>
	<div class="p-fluid">
		<div v-if="campaign !== undefined" class="p-fluid">
			{{ campaign.name }}<small v-if="campaign.dateStart !== null" class="text-muted"><br>{{ tmsRangeToHumanRange(campaign.dateStart,campaign.dateEnd) }}</small>
		</div>
	</div>
</template>

<script>
import CampaignService from "../../../service/CampaignService";
import calendarMixin from "../../../mixins/calendarMixin";

export default {
	data(){
		return {
			campaign: undefined,
			loading: true,
		}
	},
	props: {
		apiId: {}
	},
	created(){
		this.campaignService = new CampaignService()
	},
	mounted(){
		if(this.apiId === undefined){
			return;
		}

		this.campaignService.getCampagin(this.apiId)
				.then(res => {
					if(res.error === true){
						throw new Error(res.message);
					}
					this.campaign = res.data
				})
				.catch(err => {
					this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
				})
				.finally(() => {
					this.loading = false;
				})
	},
	methods: {

	},
	computed: {

	},
	mixins: [calendarMixin],
	components: {

	}
}
</script>

<style scoped>

</style>
