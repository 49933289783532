<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<ContactProfile v-if="guid !== undefined" :guid="guid"></ContactProfile>
			</div>
		</div>
	</div>
</template>

<script>
import ContactProfile from "../components/app/crm/ContactProfile";

export default {
	data() {
		return {
			dut: undefined,
			guid: undefined,
		}
	},
	created(){
		this.guid = this.$route.params.guid;
	},
	mounted(){
	},
	components: {
		ContactProfile
	}
}
</script>

<style scoped>

</style>
