

export default class CampaignService {

    async updateCampagin(campaignApiId, payload) {
        return await window.appData.$http.put(campaignApiId, payload)
            .then(res => {
                if(res.status !== 200 && res.status !== 201){
                    return {
                        status: res.status,
                        error: true,
                        data: res.data
                    };
                }

                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async getCampagin(campaignApiId) {
        return await window.appData.$http.get(campaignApiId)
            .then(res => {
                if(res.status !== 200 && res.status !== 201){
                    return {
                        status: res.status,
                        error: true,
                        data: res.data
                    };
                }

                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async createCampaign(campaignPayload) {
        return await window.appData.$http.post('/apx/event', campaignPayload)
            .then(res => {
                if(res.status !== 200 && res.status !== 201){
                    return {
                        status: res.status,
                        error: true,
                        data: res.data
                    };
                }

                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async getContactEvent(fromDate = undefined, toDate = undefined,
                          rows = 20, page = 1,
                          order = undefined, orderDirection = true) {

        const payload = {};

        if(rows !== undefined){
            payload.rows = rows;
        }

        if(page !== undefined){
            payload.page = page;
        }

        if(fromDate !== undefined){
            payload.from = fromDate;
        }

        if(toDate !== undefined){
            payload.to = toDate;
        }

        if(order !== undefined){
            payload.order = order;
        }

        if(orderDirection !== undefined){
            payload.direction = orderDirection;
        }

        return await window.appData.$http.get('/apx/event', {
            params: payload
        })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }


}
